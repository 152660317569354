<template>
  <CrudTable
    v-bind="crudTableConfig"
    :storeModule="storeModule"
    :showActionsPanel="false"
    :addItems="false"
  >
    <template v-slot:item.name="{ item }">
      <router-link :to="{ name: 'ProductCategoryDetail', params: { id: item.id }}">
        {{ item.name ? item.name : '-' }}
      </router-link>
    </template>
  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapState } from 'vuex'

export default {
  name: 'ProductCategoryTable',
  components: { CrudTable },
  data () {
    return {
      storeModule: 'productsCategory'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    })
  }
}
</script>

<style scoped>

</style>
