<template>
 <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <ProductCategoryTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import ProductCategoryTable from '@/apps/titan/components/tables/ProductCategoryTable'

export default {
  name: 'ProductCategoryList',
  components: { Layout, ProductCategoryTable },
  data () {
    return {
      sections: sections,
      title: this.$t('ProductCategory.list')
    }
  }
}
</script>

<style scoped>

</style>
